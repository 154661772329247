import React, { useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';

const ScannDirctly = ({ code, setCode }) => {
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  let isScannerRunning = false;
  let qrCodeScanner;

  useEffect(() => {
    const onScanSuccess = (decodedText) => {
      console.log('Code decoded: ', decodedText);
      setCode(decodedText);
      // Handle the decoded text as needed
    };

    const initQrCodeScanner = async () => {
      try {
        qrCodeScanner = new Html5Qrcode('qrcode-reader');
        const cameras = await Html5Qrcode.getCameras();
        setDevices(cameras);

        if (cameras.length > 0) {
          setSelectedDeviceId(cameras[0].id);
          await qrCodeScanner.start(cameras[0].id, { fps: 10, qrbox: 250 }, onScanSuccess);
          isScannerRunning = true;
        }
      } catch (error) {
        console.error('Error initializing QR code scanner: ', error);
      }
    };

    initQrCodeScanner();

    // Cleanup when component unmounts
    return () => {
      if (isScannerRunning && qrCodeScanner) {
        qrCodeScanner.stop().then(() => {
          console.log('Code scanner stopped.');
          isScannerRunning = false;
        });
      }
    };
  }, []);

  const handleDeviceChange = async (event) => {
    const deviceId = event.target.value;
    setSelectedDeviceId(deviceId);

    if (isScannerRunning && qrCodeScanner) {
      await qrCodeScanner.stop();
    }

    try {
      qrCodeScanner = new Html5Qrcode('qrcode-reader');
      await qrCodeScanner.start(deviceId, { fps: 10, qrbox: 250 });
      isScannerRunning = true;
    } catch (error) {
      console.error('Error starting QR code scanner: ', error);
    }
  };

  return (
    <div>
      <div className='qrcode-reader' id="qrcode-reader"></div>
      <div>
        <label htmlFor="cameraSelector">Select Camera:</label>
        <select id="cameraSelector" onChange={handleDeviceChange} value={selectedDeviceId}>
          {devices.map((device) => (
            <option key={device.id} value={device.id}>
              {device.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ScannDirctly;
