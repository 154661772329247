import React, { useEffect  } from 'react';
import Quagga from 'quagga';
 

const BarcodeScanner = ({code, setCode}) => {
  useEffect(() => {
    Quagga.init({
      inputStream: {
        type: 'ImageStream',
        constraints: {
          width: 640,
          height: 480,
        },
        sequence: [
          { command: 'configure', params: { type: 'file', src: true } },
          { command: 'start', params: {} },
        ],
      },
      locator: {
        patchSize: 'medium',
        halfSample: true,
      },
      numOfWorkers: 4,
      decoder: {
        readers: ['ean_reader', 'code_128_reader'],
      },
      locate: true,
    }, function (err) {
      if (err) {
        console.error(err);
        return;
      }
      console.log('Initialization finished. Ready to start');
    });

    Quagga.onDetected((data) => {
      console.log('Barcode detected and processed', setCode(data) );
    });

    return () => {
      Quagga.stop();
    };
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const imageDataUrl = reader.result;
        Quagga.decodeSingle({
          src: imageDataUrl,
          numOfWorkers: 0,  // Disable web workers to simplify the example
          inputStream: {
            size: 800,
          },
          decoder: {
            readers: ['ean_reader', 'code_128_reader'],
          },
        }, (result) => {
          if (result && result.codeResult) {
            console.log('Barcode detected and processed', result.codeResult.code);
          } else {
            console.log('No barcode detected');
          }
        });
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div id="barcode-scanner">
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleImageChange(e)}
      />
      {/* Your scanner UI goes here */}
    </div>
  );
};

export default BarcodeScanner;
