import React, { useEffect, useState } from 'react';
import TakeApicAndScann from './TakeApicAndScann';
import ScannDirctly from './ScannDirctly';
 
const QRCodeScanner = () => {

  const [Code , setCode] = useState()
  const [change , setChange] = useState(true)
   
  return (
    <div>
      { change ? <TakeApicAndScann setCode={setCode} /> :
       <ScannDirctly setCode={setCode}/>}
        <button className=' m-3 p-2 px-5 bg-main rounded-lg text-white' onClick={()=> setChange(!change) }>
          Switch to {change  ? "scann Direct" : "Take a Pic"}
        </button>
        
        {Code && <div className=' items-center justify-between px-7 flex flex-row text-center w-full h-[5rem]  fixed bottom-[20%] right-0 bg-white text-4xl font-semibold '>
         {Code} <button onClick={()=> setCode() } className=' p-2 px-4 rounded-md bg-main text-white'>Clear</button>
      </div>}
    </div>
  );
};

export default QRCodeScanner;
