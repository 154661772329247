// Importing necessary dependencies and components
import React, { useState, useRef, useEffect, useContext } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import menuIndex from "../assest/MenuIndex.svg";
import importRefromDevice from "../assest/ImportRe.svg";
import importRefromEmail from "../assest/ImportEmail.svg";
import { addNewItemID } from "../Functions/addNewItemID";
import ScannRe from "../assest/ScannRe.svg";
import { checkAndAddReceiptsToDB } from "../Functions/AddReceptTotheLocalDB";
import { Cropperr } from "./Cropper";
import { Usercontext } from "../context/Usercontexte";
import { themecontext } from "../context/Themecontexte";
import Social from "./Account/Socials";
 ;

// Define the Menu component
function Menu({ isOpen, setIsOpen }) {
  // State variables
  const [img, setImg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSocialopen, setIsSocialopen] = useState(false);
  const { setMassage, setReceiptes, setHighlightedItems } = useContext(Usercontext);
  const { theme } = useContext(themecontext);
  const refMenu = useRef();

  useEffect(() => {
    // Function to handle the click outside the div
    const handleClickOutsidePeriod = (event) => {
      if (refMenu.current && !refMenu.current.contains(event.target)) {
        // Clicked outside the div, close the menu
        setIsOpen(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutsidePeriod);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutsidePeriod);
    };
  }, []);

  // Generate unique ID for the receipt
  function generateUniqueId() {
    return "_" + Math.random().toString(36).substr(2, 9);
  }

  // Handle file selection
  function onFileSelection(e, source) {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;

      // If it's an image
      if (fileType.startsWith("image/")) {
        setImg({
          file: selectedFile,
          Source: source
        });
      } else if (fileType === "application/pdf") {
        // If it's a PDF
        const created_at = new Date().toISOString();
        
        const receiptData = {
          id: generateUniqueId(),
          image: selectedFile,
          source: "Imported PDF Receipt",
          file_type: "pdf_file",
          created_at: created_at,
        };
        // Add PDF receipt to the local database
        checkAndAddReceiptsToDB(
          receiptData,
          setMassage,
          addNewItemID,
          setReceiptes,
          setHighlightedItems
        );
      } else {
        console.log(`Unsupported file type`);
      }
    }
  }

 
  return (
    <>
      {/* Main menu container */}
      <div className="menu relative">
        {/* Toggle button for opening/closing the menu */}
        <span
          ref={refMenu}
          onClick={() => setIsOpen(!isOpen)}
          className="text-main bg-main bg-opacity-25 rounded-sm w-full h-[6rem] py-[.08rem] px-3 -mr-2 cursor-pointer"
        >
          <KeyboardArrowDownIcon />
        </span>
        <span id={""} className="menu0"></span>
        {/* Hidden image for menu styling */}
        <img
          style={{
            visibility: "hidden",
          }}
          id="menu"
          className="z-50 absolute scale-[3] top-24 -left-5 w-full"
          src={menuIndex}
          alt=""
        />
        {/* Actual menu content */}
        <div
          className={`text-sec flex flex-col duration-150 ease-out text-sm overflow-hidden justify-between w-[13rem] ${
            isOpen ? "h-[6rem] py-1 border-[1px]" : "h-0 py-0 border-[0px]"
          } ${
            !theme ? "bg-[#212121]" : "bg-white"
          } absolute top-10 border-solid  border-[#F2F2F2] rounded-md right-[-150%] shadow-lg`}
        >
          {/* Scan receipt option */}
          <label
            onClick={() => setIsLoading(true)}
            className="gap-1 flex flex-row items-center px-2"
            htmlFor="ScannReceipt"
          >
            <img src={ScannRe} alt="" />
            Scan receipt
          </label>
          {/* File input for scanning receipt */}
          <input
            className="z-50 w-full h-6"
            type="file"
            id="ScannReceipt"
            onChange={(e) => onFileSelection(e, "Scanned Receipt")}
            accept="image/*"
            style={{ display: "none" }}
            capture="environment"
          />
          {/* Separator line */}
          <span className="w-full h-[1px] bg-[#F2F2F2]"></span>
          {/* Import receipt from device option */}
          <label
            onClick={() => setIsLoading(true)}
            className="gap-1 flex flex-row items-center px-2"
            htmlFor="importFromEmail"
          >
            <img src={importRefromDevice} alt="" />
            Import receipt from device
          </label>
          {/* File input for importing receipt from device */}
          <input
            className="z-50 w-full h-6"
            type="file"
            style={{ display: "none" }}
            id="importFromEmail"
            onChange={(e) => onFileSelection(e, "Imported Image Receipt")}
            accept="image/*, application/pdf"
          />
          {/* Separator line */}
          <span className="w-full h-[1px] bg-[#F2F2F2]"></span>
          {/* Import receipt from email option */}
          <p onClick={() => setIsSocialopen(true)} className="gap-1 flex flex-row items-center px-2">
            <img src={importRefromEmail} alt="" /> Import receipt from email
          </p>
        </div>
      </div>
      {/* Cropper component for image cropping */}
      {img && (
        <Cropperr
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setImage={setImg}
          image={img}
        />
      )}
      {/* Social component */}
      <Social   text={'To import receipts from your email, please select your email provider below.'} isOpen={isSocialopen} setIsOpen={setIsSocialopen} />
    </>
  );
}

// Export the Menu component
export default Menu;
