// imageCompressionUtil.js
import Compressor from 'compressorjs';

export const compressImage = async (file) => {
    new Compressor(file, {
        quality: 0.6,
        success(result) {
          // Use the compressed image result for further client-side processing
          console.log('Compressed image result:', result);
           return result
        },
        error(err) {
          console.log(err.message);
        },
      });
};
